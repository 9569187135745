function formatCity(city) {
  if (city.loading) return city.text;

  var city_str = city.title;
  if (city.area) {
    city_str += ', ' + city.area;
  }
  if (city.region) {
    city_str += ', ' + city.region;
  }

  var markup = city_str;

  return city_str;
}

function formatCitySelection(city) {
  return city.title || city.text;
}

function update_city_select2() {
  $('#user_city_select, #order_city_select, #address_city_select').select2({
    ajax: {
      url: $('.get_city_change_url').data('url'),
      dataType: 'json',
      delay: 250,
      data: function (params) {
        offset = 0;
        if (params.page) {
          offset = (params.page - 1) * 8;
        }
        return {
          q: params.term,
          offset: offset
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;
        return {
          results: data.response.items,
          pagination: {
            more: (params.page * 8) < data.response.count
          }
        };
      },
      cache: true
    },
    placeholder: "Выберите город",
    minimumInputLength: 1,
    escapeMarkup: function (markup) {
      return markup;
    },
    templateResult: formatCity,
    templateSelection: formatCitySelection,
    dropdownCssClass: 'select2_dropdown',
  });
  $(document).on('click', '.popup-link.select-city-link', function (e) {
    e.preventDefault();
    var form = $(this).closest('.popup_content').find('form');
    var city_select = form.find('select[name=city_id]');
    city_select.append('<option value="' + $(this).data('id') + '">link</option>');
    city_select.find("option[text=link]").attr("selected", "selected");
    $(this).closest('.popup_content').find('form').submit();
  });
  $('#user_city_select, #order_city_select').change(function () {
    $(this).closest('form').submit();
  })
}

function formatFonts(font) {
  if (!font.id) {
    return font.text;
  }
  var $font = $(
    '<span style=\'font-family: "' + font.element.value + '"\'> ' + font.text + '</span>'
  );
  return $font;
}

function initialize_selects() {
  function formatRating(rating) {
    if (!rating.id) {
      return rating.text;
    }
    if (rating.element.value === 'all') {
      return rating.text;
    }
    var star_count = parseInt(rating.element.value);
    var markup = '<div class="rate_star">';
    for (i = 1; i <= 5; i++) {
      if (i <= star_count) {
        markup += '<span class="star fill"></span>';
      } else {
        markup += '<span class="star empty"></span>';
      }
    }
    return $(markup);
  }

  $('select.use_select2_plugin:not(.hidden)').select2({
    minimumResultsForSearch: Infinity
  });

  $('.select_rating').select2({
    minimumResultsForSearch: Infinity,
    templateResult: formatRating,
    templateSelection: formatRating,
    placeholder: 'Выберите рейтинг'
  });

  $('.choose_city select').select2();
  update_city_select2()
}

function initialize_selects_in_block($block, params) {
  params = params || {
    minimumResultsForSearch: Infinity
  };
  $block.find('select').select2(params);
}

var template = function (option) {
  var color = {
    '0': 'red',
    '1': 'grey',
    '3': 'orange',
    '4': 'green',
    '5': 'red',
    '6': 'red'
  } [option.id];
  return $('<span style="color: ' + color + ';">' + option.text + '</span>');
};

$(document).ready(function () {
  if ($('select.use_select2_plugin').length) {
    initialize_selects();
    $('.order-status-select')
      .select2({
        width: '120px',
        dropdownCssClass: 'select2_dropdown',
        minimumResultsForSearch: Infinity,
        templateResult: template,
        templateSelection: template
      })
      .change(function () {
        var $this = $(this);
        $this.find('option[value="1"]').remove();
        var url = $this.find('option[value="' + $this.val() + '"]').data('url');
        $.ajax({
          url: url,
          method: 'GET',
          success: function () {
            var $tr = $this.closest('tr');
            if ($this.val() === '3') {
              if ($tr.find('a.download-link').length > 0) {
                $tr.find('a.download-link')[0].click();
                $tr.find('a.download-link').parent().css('background-color', '#ccc');
              }
              if ($tr.find('a.print-link').length > 0) {
                $tr.find('a.print-link')[0].click();
                $tr.find('a.print-link').parent().css('background-color', '#ccc');
              }
            }
          },
          error: function () {
            alert('Произошла ошибка. Попробуйте позже.');
          }
        })
      });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const modal = document.getElementById("trackerModal");
  const closeBtn = document.getElementById("closeModal");
  var orderId = null;


  function openModal(title, trackerValue) {
    modal.style.display = "block";
    document.getElementById("modalTitle").innerText = title;
    document.getElementById("trackerNumber").value = trackerValue || "";


  }

  document.querySelectorAll('.download-tracker').forEach(link => {
    link.addEventListener('click', function (event) {
      event.preventDefault();

      const isEdit = this.innerText.includes("Изменить");
      const currentTrackerValue = document.getElementById("trackerValue") ? document.getElementById("trackerValue").innerText : null;
      orderId = this.id;
      openModal(isEdit ? "Изменить трек-номер" : "Добавить трек-номер", currentTrackerValue);
    });
  });
  
  if (closeBtn) {
    closeBtn.onclick = function () {
      modal.style.display = "none";
    }
  }

  window.onclick = function (event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }

  document.getElementById("trackerForm").addEventListener("submit", function (event) {
    event.preventDefault();
    const trackerNumber = document.getElementById("trackerNumber").value;
    
    const url = document.querySelector('button[type="submit"]').getAttribute('url');
    $.ajax({
      url: url,
      method: 'POST',
      contentType: 'application/json',
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
      },
      data: JSON.stringify({ tracker_number: trackerNumber, order_id: orderId }),
      success: function() {
        location.reload();
      },
      error: function() {
        alert('Произошла ошибка. Попробуйте позже.');
      }
    });
    modal.style.display = "none";
  });
});