$(document).ready(function () {
  if (!$('.page_cart').length) return;

  var getMinDeliveryCostByPoints = function() {
    var total = 100000;
    $('.point_list .list .point .cost').each(function () {
      var new_total = parseInt($(this).text());
      if (new_total <= total) {
        total = new_total
      }
    });
    return total;
  };
  var getMinDeliveryDateByPoints = function() {
    var date = {
      int: 20800101,
      text: ''
    };
    $('.point_list .list .point .date').each(function() {
      var point_date = parseInt($(this).data('int'));
      if (point_date <= date.int) {
        date.int = point_date;
        date.text = $(this).text();
      }
    });
    return date;
  };
  var updDeliveryPointInfo = function() {
    var delivery_method = $('.delivery_method input:checked').val();
    if (delivery_method === 'courier' || delivery_method === 'post' || delivery_method === 'papara_fast') {
      var point_info = $('.delivery_method input:checked').closest('.button_wrapper ').find('.point_info').text();
      $('.delivery_point_info').val(point_info).prop('disabled', false);
    } else {
      $('.delivery_point_info').val('').prop('disabled', true);
    }
  };
  var updDeliveryMethod = function() {
    // delivery point
    var $delivery_point_cost = $('.delivery_method .delivery_point .cost');
    var $delivery_point_date = $('.delivery_method .delivery_point .date');
    var $selected_point = $('.point_list .list .point .take input:checked').closest('.point');
    var $delivery_message = $('#pjax_container .product_final .delivery_message');
    var free_from = $delivery_message.data('free-from');
    // set delivery point cost
    if ($('#pjax_container .product_final .delivery_message').data('free')) {
      $delivery_point_cost.attr('data-int', 0).text('бесплатно');
    } else {
      if ($selected_point.length) {
        var cost = $selected_point.find('.cost').text();
      } else {
        var cost = getMinDeliveryCostByPoints();
      }
      $delivery_point_cost.attr('data-int', cost).text(
          cost + ' руб., бесплатно от ' + free_from + ' руб.'
      );
    }
    // set delivery point date
    if ($selected_point.length) {
      var date = {
        int: $selected_point.find('.date').data('int'),
        text: $selected_point.find('.date').text() };
    } else {
      var date = getMinDeliveryDateByPoints();
    }
    $delivery_point_date.attr('data-int', date.int).text(date.text);
    // delivery post
    var $post_cost = $('.delivery_method .post .cost');
    // set post cost
    if ($('#pjax_container .product_final .delivery_message').data('free')) {
      $post_cost.attr('data-int', 0).text('бесплатно');
    } else {
      var cost = $post_cost.data('paid-delivery');
      $post_cost.attr('data-int', cost).text(
          cost + ' руб., бесплатно от ' + free_from + ' руб.'
      );
    }
    // COURIER
    var courier_free = $delivery_message.data('courier-free');
    var courier_cost = $delivery_message.data('courier-cost');
    var $courier_cost = $('.delivery_method .courier .cost');
    if (courier_free) {
      $courier_cost.attr('data-int', 0).text('бесплатно');
    } else {
      var courier_free_from = $delivery_message.data('courier-free-from');
      $courier_cost.attr('data-int', courier_cost).text(
          courier_cost + ' руб., бесплатно от ' + courier_free_from + ' руб.'
      );
    }
    // FAST
    var fast_cost = $delivery_message.data('fast-cost');
    var $fast_cost = $('.delivery_method .papara_fast .cost');
    $fast_cost.attr('data-int', fast_cost).text(fast_cost + ' руб.');
  };
  var updDeliveryDate = function() {
    $delivery_method = $('.delivery_method input:checked').closest('.button_wrapper ');
    if ($delivery_method.hasClass('delivery_point') || $delivery_method.hasClass('courier') || $delivery_method.hasClass('papara_fast')) {
      $('.delivery_date b').text($delivery_method.find('.date').text());
      $('.delivery_date').removeClass('invisible');
    } else {
      $('.delivery_date').addClass('invisible');
    }
  };
  var updUserInfo = function() {
    var delivery_method = $('.delivery_method input:checked').val();
    var $user_info = $('.user_info');
    if (delivery_method === 'delivery_point') {
      $('.papara_fast_notice').addClass('hidden');
      var $fields = $user_info.find('.index, .street, .house, .housing, .apartment, .last_name, .first_name, .middle_name');
      $fields.addClass('hidden').find('input').prop('required', false).prop('disabled', true);
      $('.point_list').addClass('showed').find('input').prop('required', true).prop('disabled', false);
    }
    if (delivery_method === 'courier' || delivery_method === 'post' || delivery_method === 'papara_fast') {
      var $required_fields = $user_info.find('.street, .house');
      var $fields = $user_info.find('.housing, .apartment');
      if (delivery_method === 'post') {
        $required_fields = $user_info.find('.index, .street, .house, .last_name, .first_name, .middle_name');
        $user_info.find('.index').removeClass('hidden').find('input').prop('disabled', false);
      } else {
        $user_info.find('.index').addClass('hidden').find('input').prop('disabled', true);
      }
      $required_fields.removeClass('hidden').find('input').prop('required', true).prop('disabled', false);
      $fields.removeClass('hidden').find('input').prop('disabled', false);
      $('.point_list').removeClass('showed').find('input').prop('required', false).prop('disabled', true);
      $index = $user_info.find('.index');
      if (delivery_method === 'papara_fast') {
        $('.papara_fast_notice').removeClass('hidden');
      } else {
        $('.papara_fast_notice').addClass('hidden');
      }
    }
  };
  var updPaymentMethod = function() {
    var delivery_method = $('.delivery_method input:checked').val();
    var payment_method = $('.payment_method input:checked').val();
    if (delivery_method === 'post') {
      $('.payment_method .button_wrapper.cash .commission').text('комиссия от 2%');
      if (payment_method === 'cash') {
        $('.payment_message ').removeClass('hidden');
      } else {
        $('.payment_message ').addClass('hidden');
      }
    } else {
      $('.payment_method .button_wrapper.cash .commission').text('без комиссии');
      $('.payment_message ').addClass('hidden');
    }
  };
  var updFinal = function() {
    var products_amount = $('#pjax_container .product_final .amount .total').data('int');
    $('.final .price .sum').text(products_amount + ' руб.');
    var $selected_delivery = $('.delivery_method input:checked').closest('.button_wrapper');
    var delivery_cost = $selected_delivery.find('.cost').attr('data-int');
    if (delivery_cost) {
      $('.final .delivery .cost').text(delivery_cost + ' руб.');
      var final_payment = parseInt(products_amount) + parseInt(delivery_cost);
      var $delivery_modifier_div = $('.final .papara_fast_notice');
      if ($delivery_modifier_div.length && !$delivery_modifier_div.hasClass('hidden')) {
        var delivery_modifier_cost = 0;
        $('.item_delivery_modifier_cost').each(function() {
          delivery_modifier_cost += parseInt($(this).data('int'));
        });
        final_payment += parseInt(delivery_modifier_cost);
        $delivery_modifier_div.find('.cost').text(delivery_modifier_cost + ' руб.');
      }
      $('.final .payment .amount').text(final_payment + ' руб.');
    }
  };
  var product_quantity_timer;

  // PJAX CONTAINER EVENTS
  // ОТПРАВКА PJAX
  $(document).on('submit', '.pjax_cart', function (e) {
    $.pjax.submit(e, {
      container: '#pjax_container',
      fragment: '#pjax_container',
      push: false
    });
  });
  // УМЕНЬШЕНИЕ КОЛИЧЕСТВА
  $(document).on('click', '.quantity_change.minus', function () {
    var $input = $(this).parent().find('.count_field'),
      $count_minus = +($input.val()) - 1;
    if ($count_minus < 1) {
      return;
    }
    $input.val($count_minus);
    $input.change();
    $(this).closest('form').submit();
  });
  // УВЕЛИЧЕНИЕ КОЛИЧЕСТВА
  $(document).on('click', '.quantity_change.plus', function () {
    var $input = $(this).parent().find('.count_field'),
      $count_plus = +($input.val()) + 1;
    $input.val($count_plus);
    $input.change();
    $(this).closest('form').submit();
  });
  // ИЗМЕНЕНИЕ КОЛИЧЕСТВА
  $(document).on('keyup', '.pjax_cart .count_field', function () {
    var $count_field = $(this);
    if (product_quantity_timer) clearTimeout(product_quantity_timer);
    product_quantity_timer = setTimeout(function () {
      if ($count_field.val() == 0) $count_field.val(1);
      $count_field.closest('form').submit();
    }, 300);
  });
  // УДАЛЕНИЕ
  $(document).on('click', '.product .delete', function () {
    $(this).closest('.product').find('.delete_confirm').removeClass('hidden');
  });
  $(document).on('click', '.product .delete_confirm .no', function () {
    $(this).closest('.delete_confirm').addClass('hidden');
  });
  $(document).on('click', '.product .delete_confirm .yes', function () {
    $(this).closest('.delete_confirm').find('form').submit();
  });
  // ОТОБРАЖЕНИЕ ПРОМОКОДА
  $(document).on('click', '.promo_code .show', function () {
    $('.promo_code').addClass('shown');
  });
  // ПРИМЕНЕНИЕ ПРОМОКОДА
  $(document).on('submit', '.promo_code form', function (e) {
    e.preventDefault();
    var $form = $(this);
    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      data: $(this).serialize(),
      success: function (data) {
        if (data.success_url) {
          $.pjax.reload({
            container: '#pjax_container',
            fragment: '#pjax_container',
            push: false
          })
        }
        if (data.errors) {
          $form.closest('.promo_code').find('.message').text(data.errors).addClass('red');
        }
      }
    });
  });
  // ОТМЕНА ПРОМОКОДА
  $(document).on('click', '.promo_code .cancel', function (e) {
    if ($(this).closest('.promo_code').hasClass('activated')) {
      $(this).closest('form').attr('action', $(this).data('action')).submit();
    } else {
      $('.promo_code').removeClass('shown').find('.message').text('').removeClass('red');
    }
  });
  // ЗАВЕРШЕНИЕ PJAX
  $(document).on('pjax:success', function () {
    updDeliveryDate();
    updDeliveryPointInfo();
    updDeliveryMethod();
    updUserInfo();
    updFinal();
  });
  // ОТПРАВКА ФОРМЫ BUY
  $(document).on('submit', '.page_cart .buy', function(e) {
    show_loader();
  });

  // EVENTS
  var $delivery_method_input = $('.delivery_method input');
  var $payment_method_input = $('.payment_method input');
  $delivery_method_input.change(function () {
    updDeliveryDate();
    updDeliveryPointInfo();
    updUserInfo();
    updPaymentMethod();
    updFinal();
  });
  $('.point_list .point .take input').change(function() {
    updDeliveryMethod();
    updFinal();
  });
  $payment_method_input.change(updPaymentMethod);

  // INIT
  updDeliveryMethod();
  $delivery_method_input.first().click();
  $delivery_method_input.first().change();
  if ($('.payment_method').hasClass('offline')) {
    $payment_method_input.filter(':eq(1)').click();
  } else {
    $payment_method_input.filter(':eq(0)').click();
  }
});
$(document).ready(function () {
  // ЭТО НУЖН ПЕРЕНЕСТИ В INDEX PAGE
  // УДАЛЕНИЕ ТОВАРА В ПОПАПЕ КОРЗИНЫ
  $(document).on('click', 'header .cart .items_block_full .item_delete', function (e) {
    e.preventDefault;
    var $parent = $(this).closest('.item_cell');
    $(this).addClass('hidden');
    $parent.find('.description').addClass('hidden');
    $parent.find('.confirm-delete').removeClass('hidden');
    return false;
  });
  $(document).on('click', 'header .cart .items_block_full .confirm-delete .yes', function (e) {
    e.preventDefault;
    // var $parent = $(this).closest('.item_cell');
    // ya_commerce_cart(
    //   $parent.find('.action_yandex_commerce').data('product-info'),
    //   "remove",
    //   parseInt($parent.find('.item_count').text()),
    //   $parent.find('.action_yandex_commerce').data('local-storage-uuid')
    // );
    $.ajax({
      url: $(this).attr('data-url'),
      type: 'POST',
      data: {},
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
      },
      success: function (data, textStatus) {
        if (textStatus == 204) {
          location.reload();
        } else {
          $('header .header_content .cart').html(data);
        }
      },
      error: function (request, status, error) {
        alert("Произошла ошибка при удалении товара.\nОбновите страницу и попробуйте снова.");
      }
    });
    return false;
  });
  $(document).on('click', 'header .cart .items_block_full .confirm-delete .no', function (e) {
    e.preventDefault;
    var $parent = $(this).closest('.item_cell');
    $parent.find('.confirm-delete').addClass('hidden');
    $parent.find('.item_delete').removeClass('hidden');
    $parent.find('.description').removeClass('hidden');
    return false;
  });
});
